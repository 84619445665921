.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 80%;
    max-width: 600px;
    max-height: 80%;
    overflow-y: auto;
  }
  
  .modal textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    resize: vertical;
  }
  
  .modal button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .modal button:hover {
    background-color: #0056b3;
  }