.club {
    text-align: center;
    margin: 10px;
  }
  
  .club-icon {
    width: 50px;
    height: 50px;
  }
  
  .club-name {
    font-size: 0.8em;
    margin-top: 5px;
    font-family: 'Karantina', sans-serif;
    max-width: 100px; 
    word-wrap: break-word; 
  }