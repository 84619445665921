.AdminAsignarRol {
    text-align: center;
  }

  .AdminInscribir-main {
    margin-top: 0px;
  }
  .asignar-form {
    display: inline-block;
    align-items: center;
    text-align: left;
    flex-direction: column;
    justify-content: center;
  }
  
  .form-group {
    display: flex;
    margin-bottom: 15px;
    text-align: right;
    flex-direction: row;
    justify-content:center;
  }

  label {
    font-family: 'Karantina', sans-serif;
    font-weight: 500;
    align-items: center;
    display:flex;
    justify-content: right;
    font-size: 1.05em;
    margin-right: 0px;
    width: 200px;
    height: 40px;
    padding: 10px;
    font-size: 1em;
    border-radius: 5px;
    box-sizing: border-box;
  }