.RecuperarPassword {
    text-align: center;
  }
  
  .RecuperarPassword-main {
    margin-top: 20px;
  }
  
  h2 {
    font-family: 'Karantina', sans-serif;
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .recuperar-password-form {
    display: inline-block;
    text-align: left;
  }
  
  .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  label {
    font-family: 'Karantina', sans-serif;
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    font-size: 1.05em;
    margin-right: 15px;
    width: 150px;
  }
  
  input {
    flex: 1;
    max-width: 225px;
    width: 300px;
    height: 45px;
    padding: 10px;
    font-size: 0.8em;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    text-align: center;
  }
  
  .error {
    color: black;
    font-size: 0.9em;
    margin-top: 10px;
  }
  
  .success {
    color: black;
    font-size: 0.9em;
    margin-top: 10px;
  }