.AdminVerInscritos {
    text-align: center; 
  }

.centered-title {
  flex-grow: 1;
  text-align: center;
  margin-right: 90px;
}
  .back-button img {
    width: 30px; 
    height: 30px;
  }

  .club-list-container {
    padding: 20px;
  }
  