.ficha-form {
    display: flex;
    flex-direction: column;
    align-items: center; 
  }
  
  .ficha-section {
    margin-bottom: 20px;
    flex-direction: row;
  }
  
  .ficha-section h3 {
    background-color: #000000;
    color: white;
    padding: 10px;
    margin: 0 -10px 10px;
  }
  
  .ficha-photo-container {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .ficha-photo {
    width: 150px;
    height: 150px;
    border: 2px dashed #ddd;
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #999;
    margin: 0 auto;
  }
  
  .ficha-form-group {
    display:inline-flex;
    flex-direction: row;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  
  .ficha-form-group label {
    margin-bottom: 5px;
  }
  
  .ficha-form-group input,
  .ficha-form-group textarea {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
    width: 180px;
    resize: none;
  }
  
  
  
  .ficha-button-group {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }