.grey-button {
    display: block;
    width: 200px;
    padding: 10px;
    margin: 10px auto;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    background-color: F5F5F5;
    border-color: #000;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .grey-button:hover {
    background-color: #000000; 
    color: #fff; 
  }